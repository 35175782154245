import 'devbridge-autocomplete';
//--------------------------------------------------
// Global

jQuery(($) => {
  //--------------------------------------------------
  // Form helpers

  $('input:radio').each(function () {
    const parent = $(this).parent('div');
    $(this).on('click', function () {
      $('label', parent).removeClass('checked');
      $(this).next('label').addClass('checked');
    });
  });

  $('input:checkbox').each(function () {
    $(this).on('click', function () {
      $(this).next('label').toggleClass('checked');
    });
  });

  //--------------------------------------------------
  // Global search

  $('#global-search').autocomplete({
    serviceUrl: '/search.json',
    appendTo: $('.js-search-wrapper'),
    onSelect(suggestion) {
      window.location.href = suggestion.url;
    },
    transformResult(response) {
      const {globals} = JSON.parse(response).meta;
      const elements = JSON.parse(response).data;

      const data = globals.concat(elements);

      // if we want to add search results mapping, we can do it here??
      return {
        suggestions: $.map(data, (dataItem) => ({
          value: dataItem.title,
          url: dataItem.url,
        })),
      };
    },
  });

  //--------------------------------------------------
  // Attach 'close' button and add functionality on mobile

  function mobileSearchClose(x) {
    const elem = $('.search-expandable .site-search');
    if (x.matches) {
      // If media query matches
      elem.prepend(
        '<a href="#" class="close" data-target="search"><span class="off-screen">Close search</span></div>',
      );
      $('.close', elem).on('click', (e) => {
        e.preventDefault();
        $('body').removeClass('js-open-toolbar');
        $('.search-trigger').removeClass('js-open');
        $('#search').hide();
      });
      $('.search-trigger').addClass('search-overlay');
    } else {
      $('.close', elem).remove();
      $('.search-trigger').removeClass('search-overlay');
    }
    $('.search-trigger').removeClass('search-overlay');
  }

  const x = window.matchMedia('(max-width: 760px)');
  mobileSearchClose(x); // Call listener function at run time
  x.addListener(mobileSearchClose); // Attach listener function on state changes

  //--------------------------------------------------
  // Expandable Content

  let navVisible = false;
  $('#toolbar').css('transform', 'translateX(100%)');
  $('#toolbar').css('visibility', 'hidden');
  function toggleNav() {
    navVisible = !navVisible;
    if (navVisible) {
      $('body').attr('data-nav-open', '1');
      $('#toolbar').css('visibility', 'visible');
      $('#toolbar').css('transform', 'translateX(0)');
    } else {
      $('body').attr('data-nav-open', '0');
      $('#toolbar').css('transform', 'translateX(100%)');
    }
  }

  $('.mobile-nav-trigger').on('click', toggleNav);

  $('.js-trigger').on('click', function (e) {
    e.preventDefault();
    const trigger = $(this);
    const open = 'js-open';
    const openToolbar = 'js-open-toolbar';
    const focus = $(this).attr('href');

    //--------------------------------------------------
    // Target a specific container
    // Otherwise target next expandable

    if (trigger.data('target')) {
      const target = $(this).data('target');
      var expandable = $(`#${target}`);
    } else {
      var expandable = $(this).next('.js-expandable');
    }

    //--------------------------------------------------
    // Show / Hide Expandable

    if (!$(trigger).hasClass(open)) {
      $(expandable).show();
      $(trigger).addClass(open);

      if ($(trigger).hasClass('search-overlay')) {
        $('body').addClass(openToolbar);
      }

      // Focus on the target element
      if (focus.match('^#')) {
        $(focus).focus();
      }

      //--------------------------------------------------
      // Hide all except this

      $('.js-expandable').not(expandable).hide();
      $('.js-trigger').not(trigger).removeClass(open);
    } else {
      $(expandable).hide();
      $(trigger).removeClass(open);
      $('body').removeClass(openToolbar);

      // Unfocus the target element
      if (focus.match('^#')) {
        $(focus).blur();
      }
    }

    //--------------------------------------------------
    // Hide search on escape

    $(document).on('keydown', (e) => {
      if (e.keyCode === 27) {
        // ESC
        $(expandable).hide();
        $(trigger).removeClass(open);
        $('body').removeClass(openToolbar);
        $(focus).blur();
      }
    });
  });

  function outsideClick(event, notelem) {
    let clickedOutsideEl = true;

    if (event.target == notelem || notelem.contains(event.target)) {
      clickedOutsideEl = false;
    }
    if (clickedOutsideEl) return true;
    return false;
  }

  document.querySelectorAll('.top-level > a').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      if (el.parentElement.classList.contains('sub-menu-visible')) {
        el.parentElement.classList.remove('sub-menu-visible');
      } else {
        el.parentElement.classList.add('sub-menu-visible');
        // timeout is because Safari was registering this within the existing click
        setTimeout(() => {
          window.addEventListener('click', (e) => {
            if (outsideClick(e, el.closest('.top-level'))) {
              el.parentElement.classList.remove('sub-menu-visible');
            }
          });
        }, 500);
        window.addEventListener(
          'keydown',
          (e) => {
            if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
              el.parentElement.classList.remove('sub-menu-visible');
              el.blur();
            }
          },
          true,
        );
      }
    });
  });

  //--------------------------------------------------
  // Scroll to anchor

  if ($('#cookiesManagement').length == 0) {
    // Checking that the cookies management tab feature is not present. Seems to be a bug where cookie management tabs where auto scrolling to top of page when interacted with

    $('a[href*="#"]')
      .not('[href="#"],.sr-skip,.search-trigger')
      .on('click', function (event) {
        if (
          location.pathname.replace(/^\//, '') ==
            this.pathname.replace(/^\//, '') &&
          location.hostname == this.hostname
        ) {
          let target = $(this.hash);
          target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);

          if (target.length) {
            event.preventDefault();
            $('html, body').animate(
              {
                scrollTop: $(target).offset().top,
              },
              1000,
              () => {
                const $target = $(target);
                $target.focus();
                if ($target.is(':focus')) {
                  // Checking if the target was focused
                  return false;
                }
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              },
            );
          }
        }
      });
  }
});
